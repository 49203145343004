.form_container {
  background-color: #ffffff;
  box-shadow: $box-shadow;
  padding: 48px 32px 32px;

  .form_list_inner ul {
    display: grid;
    grid-template-columns: 32% 32% 32%;
    column-gap: 2%;
  }

  .form_button {
    text-align: center;
  }
}

@media (max-width: 1200px) {
  .form_container .form_list_inner ul {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
}

@media (max-width: 550px) {
  .form_container {
    padding: 45px 24px 24px;

    .form_list_inner ul {
      grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
    }
  }
}
